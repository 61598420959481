/**
 * Base URL for the API.
 * @constant
 * @type {string}
 * @default
 */
export const API_BASE_URL = (process && process.env && process.env.REACT_APP_BACKEND_URL) || 'https://meeting-room-suite-api.booking.com/api';

// TODO: Set different keys for different environments
export const AI_INSTRUMENTATION_KEY = '75d0fc78-0988-43f3-a530-c0cac4916d7a';

export const APP_TITLE = 'Meeting Room Suite';

export const RBAC_BASIC_VIEWER = 'com.booking.services.meetingroomsuite.viewer__allow';
export const RBAC_ROOMS = 'com.booking.services.meetingroomsuite.admin_rooms__allow';
export const RBAC_ROOM_PERMISSIONS = 'com.booking.services.meetingroomsuite.admin_rooms_permissions__allow';
export const RBAC_ROOM_EQUIPMENT = 'com.booking.services.meetingroomsuite.admin_equipment__allow';
export const RBAC_ROOM_SHARING = 'com.booking.services.meetingroomsuite.admin_sharing__allow';
export const RBAC_ROOM_SHARING_CANCEL = 'com.booking.services.meetingroomsuite.admin_sharing__cancel';
export const RBAC_ROOM_SHARING_APPROVE = 'com.booking.services.meetingroomsuite.admin_sharing__approve';
export const RBAC_ROOM_MAINTENANCE = 'com.booking.services.meetingroomsuite.admin_rooms_maintenance__allow';
export const RBAC_ROOM_DEPLOYMENTS = 'com.booking.services.meetingroomsuite.admin_deployments__allow';
